
import '../scss/btf/btf.scss';

import { Menu } from './components/menu/menu';
import { Header } from "./components/header/header";
import { LanguagesToggler } from './components/languages-toggler/languages-toggler';
import { ScrollTo } from "./components/scroll-to/scroll-to";
import { AsyncNavigation } from "./async-navigation/async-navigation";



document.addEventListener('DOMContentLoaded', function() {


	AsyncNavigation.initAsyncNavigation();

	if ( document.querySelector('.menu-btn') ) {
		Menu.setupMenu();
	}

	if ( document.querySelector('header') ) {
		new Header();
	}
	
	if (document.querySelector('.languages-toggler')) {
		new LanguagesToggler( document.querySelector('.languages-toggler') );
	}


	if ( document.querySelector('.scroll-to') ) {
		document.querySelectorAll('.scroll-to').forEach( (el: HTMLElement) => new ScrollTo(el) );
	}

	const heightCheck = window.innerHeight / 3;

	window.addEventListener('scroll', () => {
		if ( window.scrollY > heightCheck ) {
			document.querySelector('.bottom-banner').classList.add('active');
		} else {
			document.querySelector('.bottom-banner').classList.remove('active');
		}
	});

	document.querySelector('.close-bottom-banner').addEventListener('click', (ev: MouseEvent) => {
		ev.preventDefault();
		document.querySelector('.bottom-banner').classList.add('disabled');
	});

}, false);



import barba, { ITransitionData } from '@barba/core';
import { gsap } from "gsap";
import { Menu } from "../components/menu/menu";

export class AsyncNavigation {

	static transitionAnimator: HTMLElement = document.querySelector('.transition-animator');

	static initAsyncNavigation() {

		// Disable scrolling on back to previous page
		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		}

		// Init barbajs with default transitions (ltr, rtl)
		barba.init(
			{
				transitions: [
					{
						name: 'right-to-left-transition',
						leave: (data: any) => {
							return new Promise(resolve => AsyncNavigation.rightToLeftLeave( data, resolve ));
						},
						enter: (data: any) => {
							return new Promise(resolve => AsyncNavigation.rightToLeftEnter( data, resolve ) );
						},
						after(data: ITransitionData) {
							AsyncNavigation.pagesInit();
						}
					},
				],
				prevent: ({el, event: MouseEvent, href}) => {
					if ( el.closest('.languages-toggler') && event.type === 'click' ) {
						top.location.href = href;
						return true;
					}
					return false;
				}
			},
		);

		// Hide Loader
		AsyncNavigation.hideLoader();

		// Init pages
		AsyncNavigation.pagesInit();
	}


	static hideLoader() {
		gsap.delayedCall( 1, () => {
			gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-3'), { opacity: 1, left: '-100%', duration: 1, delay: 0.2 });
			gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-2'), { opacity: 1, left: '-100%', duration: 1, delay: 0.4 });
			gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-1'), {
				opacity: 1,
				left: '-100%',
				duration: 1,
				delay: 0.5,
				onComplete: () => {
					AsyncNavigation.transitionAnimator.classList.remove('init');
					AsyncNavigation.transitionAnimator.classList.add('left');
					AsyncNavigation.cleanAnimatorStyles();
				}
			});
		});
	}

	static leaveAnimation( data: any, resolve: any, cssProps: any ) {
		AsyncNavigation.beforeLeave(data);

		AsyncNavigation.transitionAnimator.classList.add('animate');

		gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-1'), { ...cssProps, opacity: 1, duration: .7, delay: 0.2 });
		gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-2'), { ...cssProps, opacity: 1, duration: .7, delay: 0.4 });
		gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-3'), { ...cssProps,  opacity: 1, duration: .7, delay: 0.6 });
	}


	static rightToLeftLeave( data: any, resolve: any ) {
		AsyncNavigation.setStartAnimation('right-to-left');
		AsyncNavigation.leaveAnimation( data, resolve, { right: 0 } );
		setTimeout(() => resolve() , 1500);
	}


	static enterAnimation(data: any, dir: string) {
		AsyncNavigation.afterEnter(data);

		gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-3'), { opacity: 1, left: dir, duration: 1, delay: 1.8 });
		gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-2'), { opacity: 1, left: dir, duration: 1, delay: 2.1 });
		gsap.to(AsyncNavigation.transitionAnimator.querySelector('.overlay-1'), {
			opacity: 1,
			left: dir,
			duration: 1,
			delay: 2.4,
			onComplete: () => {
				AsyncNavigation.cleanAnimatorStyles()
			}
		});

		document.querySelectorAll('header .logo svg path').forEach( el => el.removeAttribute('style') );
	}

	static rightToLeftEnter( data: any, resolve: any ) {
		const container = data.next.container;
		if ( typeof container.dataset.header !== 'undefined' ) {
			if ( container.dataset.header === 'dark' ) {
				document.body.classList.add('dark-header');
			}
		} else {
			document.body.classList.remove('dark-header');
		}

		AsyncNavigation.enterAnimation(data, '-100%');
		resolve();
	}


	static cleanAnimatorStyles() {
		AsyncNavigation.transitionAnimator.querySelector('.overlay-3').removeAttribute("style")
		AsyncNavigation.transitionAnimator.querySelector('.overlay-2').removeAttribute("style")
		AsyncNavigation.transitionAnimator.querySelector('.overlay-1').removeAttribute("style")
		AsyncNavigation.transitionAnimator.classList.remove('animate');
	}

	static setStartAnimation( direction: string ) {
		if ( direction == 'left-to-right' ) {
			AsyncNavigation.transitionAnimator.classList.remove('right');
			AsyncNavigation.transitionAnimator.classList.add('left');
		} else if ( direction == 'right-to-left' ) {
			AsyncNavigation.transitionAnimator.classList.remove('left');
			AsyncNavigation.transitionAnimator.classList.add('right');
		}
	}



	static beforeLeave( data: any ) {
		Menu.forceCloseMenu();
	}


	static afterEnter( data: any ) {
		const t: any = document.querySelector('.transition-animator');
		t.style.top = 0;

		Menu.setMenuItemActive(data.trigger);

		gsap.delayedCall( 1.5, () => window.scrollTo(0, 0) );
	}


	static pagesInit() {
		if ( document.querySelector('.page-template-home') ) {
			import('../pages/home/home').then(c => new c.Home() );
		} else if ( document.querySelector('.page-template-history') ) {
			import('../pages/history/history').then(c => new c.History() );
		} else if ( document.querySelector('.page-template-ninfeo') ) {
			import('../pages/ninfeo/ninfeo').then(c => new c.Ninfeo() );
		} else if ( document.querySelector('.page-template-timeline') ) {
			import('../pages/timeline/timeline').then(c => new c.Timeline() );
		} else if ( document.querySelector('.page-template-contacts') ) {
			import('../pages/contacts/contacts').then(c => new c.Contacts() );
		} else {
			import('../pages/page').then(c => {
				const p = new c.Page();
				p.pageSetup();
				p.pageReady();
			} );
		}
	}

}








export class Header {

	wrapper: HTMLElement;
	coverHeight: number;

	 lastScrollTop: number;

	constructor() {

		this.wrapper = document.querySelector('header');
		this.lastScrollTop = window.scrollY;

		this.setCoverHeight();

		window.addEventListener('resize', () => this.setCoverHeight() );


		// EFFETTO HIDE/SHOW SU DIREZIONE SCROLL
		window.addEventListener('scroll', (ev: any) => {

			const st = window.scrollY || document.documentElement.scrollTop;

			if ( st > 0 && !document.body.classList.contains('is-animating') ) {
				this.wrapper.classList.add('sticky');
			} else {
				this.wrapper.classList.remove('sticky');
			}

			if (st > this.lastScrollTop) {

				// downscroll code
				if ( window.scrollY > (window.innerHeight/4) ) {
					this.wrapper.classList.add('hide-out');
				}

			} else if (st < this.lastScrollTop) {
				// upscroll code
				this.wrapper.classList.remove('hide-out');
			}

			this.lastScrollTop = st <= 0 ? 0 : st;
		});
	}

	setCoverHeight() {
		if ( document.querySelector('.page-cover') ) {
			this.coverHeight = document.querySelector('.page-cover').getBoundingClientRect().height - this.wrapper.getBoundingClientRect().height;
		} else if ( document.querySelector('.home-cover') ) {
			this.coverHeight = document.querySelector('.home-cover').getBoundingClientRect().height - this.wrapper.getBoundingClientRect().height;
		}
	}

}


import { gsap } from 'gsap';

export class Menu {

	static trigger: HTMLButtonElement;
	static header: HTMLElement;
	static container: HTMLElement;
	static backdrop: HTMLElement;

	static menuTl: any;


	static setupMenu() {
		Menu.trigger   = document.querySelector('.menu-btn');
		Menu.header    = document.querySelector('header');
		Menu.container = document.querySelector('.menu-container');
		Menu.backdrop  = document.querySelector('.menu-backdrop');

		if ( Menu.trigger ) {
			Menu.trigger.addEventListener( 'click', () => {
				if ( Menu.trigger.classList.contains( 'active' ) ) {
					Menu.closeMenu();
				} else {
					Menu.openMenu();
				}
			});
		}

		Menu.backdrop.addEventListener( 'click', () => {
			Menu.closeMenu();
		});

		Menu.menuTl = gsap.timeline({ paused: true });
		Menu.menuTl.to(Menu.container, { left: 0, opacity: 1 });
	}


	static openMenu() {
		document.body.classList.add('menu-open');

		Menu.trigger.classList.add('active');

		gsap.to( 'header svg path', { fill: '#173D47' });

		gsap.fromTo('nav h3, nav a, .languages, .contact-info', {
			opacity: 0,
			left: -30
		}, {
			opacity: 1,
			left: 0,
			stagger: .15,
			ease: 'easeOutExpo'
		});

		Menu.menuTl.play();
	}


	static closeMenu( ev?: MouseEvent ) {
		gsap.to('nav h3, nav a, .languages, .contact-info', {
			opacity: 0,
			left: -30,
			stagger: .15,
			ease: 'easeOutExpo',
			onComplete: () => {
				document.body.classList.remove('menu-open');
			}
		});

		gsap.to( 'header svg path', { fill: '#FFF' });
		Menu.trigger.classList.remove('active');
		Menu.menuTl.reverse();
	}


	static setMenuItemActive( trigger: HTMLElement ) {
		Menu.container.querySelectorAll('nav a').forEach( t => t.classList.remove('active') );
		trigger.classList.add('active');
	}


	static forceCloseMenu() {
		gsap.to('nav h3, nav a, .languages, .contact-info', {
			opacity: 0,
			left: -30,
			stagger: .15,
			ease: 'easeOutExpo',
			onComplete: () => {
				document.body.classList.remove('menu-open');
				Menu.menuTl.reverse();
				Menu.trigger.classList.remove('active');
			}
		});
	}

}


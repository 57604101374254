
export class LanguagesToggler {

	wrapper: HTMLElement;
	trigger: HTMLElement;

	constructor(wrapper: HTMLElement) {
		this.wrapper = wrapper;
		this.trigger = this.wrapper.querySelector('.active');

		this.wrapper.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();

			this.toggleLangs();
		});

		this.wrapper.querySelectorAll('a').forEach( (langLink: HTMLAnchorElement) => {
			langLink.addEventListener('click', () => {
				this.wrapper.querySelectorAll('li').forEach( el => el.classList.remove('active') );
				langLink.closest('li').classList.add('active');
			});
		});
	}


	toggleLangs() {
		if ( this.wrapper.classList.contains('shown') ) {
			this.wrapper.classList.remove('shown');
		} else {
			this.wrapper.classList.add('shown');
		}
	}

}
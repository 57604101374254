
export class ScrollTo {

	wrapper: HTMLElement;

	constructor( wrapper: HTMLElement ) {
		this.wrapper = wrapper;

		const scrollTo = ( this.wrapper.dataset.scrollTo ? this.wrapper.dataset.scrollTo : '');

		if ( scrollTo && document.querySelector(scrollTo) ) {
			this.wrapper.addEventListener('click', (ev: MouseEvent) => {
				ev.preventDefault();
				document.querySelector(scrollTo).scrollIntoView({ behavior: 'smooth' });
			});
		}
	}

}

